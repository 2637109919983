$m-dark: hsl(216deg, 12%, 10%);
$m-shadow: hsl(214deg, 12%, 17%);
$m-muted: hsl(214deg, 12%, 32%);
$m-light: hsl(221deg, 9%, 57%);
$m-white: hsl(0deg, 0%, 100%);
$m-off-white: hsl(221deg, 9%, 75%);


// menu classes

.active {
  color: $m-white;
}



// game classes

.post-item .game-social a {
  justify-content: center;
}


// text
.text-center {
  text-align: center;
}

// misc
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.video-16-9 {
  aspect-ratio : 16/9;
  width: 100%;
  margin-bottom: 5px;
}

.social-inline {
  margin-right: -4px;
}

.social-inline svg {
  margin-bottom: -4px;  
}